import { Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import OutboundLink from '../../components/OutboundLink';
import Section from '../../components/Section';

const PressReleases = () => (
  <Layout title="Press Releases" className="news-and-events">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-light-blue"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../news-and-events/recent-news">News</Link>
          </li>
          <li className="breadcrumb active">Press Releases</li>
        </ul>
        <h1 className="page-title">Press Releases</h1>
        <Image filename="hero-press-releases.png" className="hero-image" />
      </div>
    </div>
    {/* Press Releases Main Content */}
    <Section>
      <Container>
        {/* Press Releases Intro */}
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2>3M Young Scientist Challenge Announcements</h2>
            <h4>
              Explore the latest news and updates about the 3M Young Scientist
              Challenge with comprehensive press releases.
            </h4>
            <p>
              For press inquiries or photos of previous challenges, please
              contact Grace Maliska at{' '}
              <OutboundLink to="mailto: gmaliska@discoveryed.com">
                GMaliska@discoveryed.com
              </OutboundLink>
              .
            </p>
          </Column>
        </Row>
        {/* Press Releases Listing */}
        <Row>
          <Column size={8} offset={2}>
            <ul className="press-releases-grid">
              {/** 2024*/}
              <li>
                <span className="press-releases__date">October 16th, 2024</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/14-year-old-named-americas-top-young-scientist-for-inventing-an-ai-handheld-pesticide-detector/">
                  14-year-old named America's Top Young Scientist for inventing an AI handheld pesticide detector
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 27, 2024</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2024-national-finalists/">
                  3M Young Scientist Challenge Announces 2024 National Finalists
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 26, 2024</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-26-state-merit-winners-and-four-honorable-mentions-in-2024-3m-young-scientist-challenge/">
                  3M and Discovery Education Recognize 26 State Merit Winners
                  and Four Honorable Mentions in 2024 3M Young Scientist
                  Challenge
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 16, 2024</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/search-begins-for-2024-americas-top-young-scientist/">
                  Search Begins for 2024 America’s Top Young Scientist
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/** 2023*/}
              <li>
                <span className="press-releases__date">October 11th, 2023</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/14-year-old-named-americas-top-young-scientist-for-development-of-skin-cancer-treatment/">
                  14-year-old named America's Top Young Scientist for development of skin cancer treatment
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 27, 2023</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2023-national-finalists/">
                  3M Young Scientist Challenge Announces 2023 National Finalists
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 26, 2023</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-24-state-merit-winners-and-4-honorable-mentions-in-2023-3m-young-scientist-challenge/">
                  3M and Discovery Education Recognize 24 State Merit Winners
                  and 4 Honorable Mentions in 2023 3M Young Scientist Challenge
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">May 24, 2023</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-name-10-grant-recipients-supporting-nations-young-scientists/">
                  3M and Discovery Education Name 10 Grant Recipients Supporting
                  Nation’s Young Scientists
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2022 */}
              <li>
                <span className="press-releases__date">December 8, 2022</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/search-begins-for-2023-americas-top-young-scientist/">
                  Search Begins for 2023 America’s Top Young Scientist
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">October 19th, 2022</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/14-year-old-from-california-named-americas-top-young-scientist/">
                  14-Year-old from California Named America's Top Young Scientist
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">July 7, 2022</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2022-national-finalists-and-honorable-mentions/">
                  3M Young Scientist Challenge Announces 2022 National Finalists
                  and Honorable Mentions <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 27, 2022</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-recognize-31-state-merit-winners-in-2022-3m-young-scientist-challenge/">
                  3M and Discovery Education Recognize 31 State Merit Winners in
                  2022 3M Young Scientist Challenge <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2021 */}
              <li>
                <span className="press-releases__date">October 20th, 2021</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-names-americas-top-young-scientist-of-2021-14-year-old-sarah-park-for-music-therapy-treatment-to-improve-mental-health/">
                  3M Names America's Top Young Scientist of 2021: 14-Year-Old Sarah Park, for Music Therapy Treatment to Improve Mental Health
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 24, 2021</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2021-national-finalists-and-honorable-mentions/">
                  3M Young Scientist Challenge Announces 2021 National Finalists
                  and Honorable Mentions <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 23, 2021</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-announce-state-merit-winners-in-the-2021-3m-young-scientist-challenge/">
                  3M and Discovery Education Announce State Merit Winners in the
                  2021 3M Young Scientist Challenge <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2020 */}
              <li>
                <span className="press-releases__date">October 14, 2020</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/americas-top-young-scientist-of-2020-14-year-old-researches-spike-protein-of-sars-cov-2-virus-to-develop-novel-antiviral-drug-to-combat-spread-of-covid-19/">
                  America’s Top Young Scientist of 2020: 14-Year-Old Researches
                  Spike Protein of SARS-CoV-2 Virus to Develop Novel Antiviral
                  Drug to Combat Spread of COVID-19 <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 23, 2020</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-young-scientist-challenge-announces-2020-national-finalists/">
                  3M Young Scientist Challenge Announces 2020 National Finalists
                  and State Merit Winners <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 30, 2020</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-search-for-americas-next-top-young-scientist-in-2020-premier-middle-school-science-competition/">
                  3M and Discovery Education Search for America’s Next Top Young
                  Scientist in 2020 Premier Middle School Science Competition
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2019 */}
              <li>
                <span className="press-releases__date">October 30, 2019</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/13-year-old-named-americas-top-young-scientist/">
                  14-Year-Old Named America’s Top Young Scientist
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 27, 2019</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-announce-national-finalists-and-state-merit-winners-in-2019-3m-young-scientist-challenge-2/">
                  3M and Discovery Education Announce National Finalists and
                  State Merit Winners in 2019 3M Young Scientist Challenge
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">March 12, 2019</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-search-for-americas-next-top-young-scientist-in-national-middle-school-competition-with-chance-to-win-25000-grand-prize/">
                  3M and Discovery Education Search for America’s Next Top Young
                  Scientist in National Middle School Competition With Chance to
                  Win $25,000 Grand Prize
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2018 */}
              <li>
                <span className="press-releases__date">October 17, 2018</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/seventh-grader-aims-to-improve-treatment-for-pancreatic-cancer-using-artificial-intelligence/">
                  Seventh Grader Aims to Improve Treatment for Pancreatic Cancer
                  Using Artificial Intelligence
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 21, 2018</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-announce-national-finalists-in-young-scientist-challenge-4/">
                  Discovery Education and 3M Announce National Finalists in
                  Young Scientist Challenge
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 11, 2018</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-recruit-americas-next-top-young-scientist-in-annual-middle-school-competition/">
                  Discovery Education and 3M Recruit America's Next Top Young
                  Scientist in Annual Middle School Competition
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2017 */}
              <li>
                <span className="press-releases__date">October 18, 2017</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/3m-and-discovery-education-celebrate-10th-anniversary-of-young-scientist-challenge-awards-americas-top-scientist-with-25000-prize/">
                  3M and Discovery Education Celebrate 10th Anniversary of Young
                  Scientist Challenge, Awards America's Top Scientist with
                  $25,000 Prize
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 20, 2017</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-celebrate-10th-anniversary-of-young-scientist-challenge-with-announcement-of-national-finalists/">
                  Discovery Education and 3M Celebrate 10th Anniversary of Young
                  Scientist Challenge with Announcement of National Finalists
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 11, 2017</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-recruit-americas-next-top-young-scientist-in-annual-middle-school-competition/">
                  3M and Discovery Education Announce First-Ever Young Scientist
                  Virtual Field Trip
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 12, 2017</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-search-for-americas-2017-top-young-scientist-chance-for-25000-and-one-of-a-kind-summer-mentorship-with-3m-scientists/">
                  Discovery Education and 3M Search for America's 2017 Top Young
                  Scientist; Chance for $25,000 and One-of-a-Kind Summer
                  Mentorship with 3M Scientists
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2016 */}
              <li>
                <span className="press-releases__date">October 19, 2016</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-name-americas-top-young-scientist-and-award-25000/">
                  Discovery Education and 3M Announce 2016 Science Competition
                  Winner
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 12, 2016</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-search-for-americas-2016-top-young-scientist/">
                  The 2016 Discovery Education 3M Young Scientist Challenge is
                  Now Open
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2015 */}
              <li>
                <span className="press-releases__date">October 14, 2015</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-announce-2015-science-competition-winner/">
                  Discovery Education and 3M announce 2015 Science Competition
                  Winner
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">June 18, 2015</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-announce-national-finalists-in-2015-young-scientist-challenge/">
                  Discovery Education and 3M Announce National Finalists in 2015
                  Young Scientist Challenge
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 15, 2015</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-search-for-americas-top-young-scientist/">
                  Discovery Education and 3M Search for America's Top Young
                  Scientist
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              {/* 2014 */}
              <li>
                <span className="press-releases__date">October 16, 2014</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-announce-2014-science-competition-winner/">
                  Discovery Education and 3M Announce 2014 Science Competition
                  Winner
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">July 9, 2014</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-announce-national-finalists-in-2014-young-scientist-challenge/">
                  Discovery Education and 3M Announce 2014 Science Competition
                  Winner
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
              <li>
                <span className="press-releases__date">January 15, 2014</span>
                <OutboundLink to="https://www.discoveryeducation.com/details/discovery-education-and-3m-search-for-americas-2014-top-young-scientist/">
                  Discovery Education and 3M Search for America's 2014 Top Young
                  Scientist
                  <Icon name="externallink" />
                </OutboundLink>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default PressReleases;
